import React from 'react';
import GlobalNav from './globalNav';
import { Helmet } from "react-helmet";
import { CustomerContextProvider } from "../../contexts/CustomerContext";
import { CartContextProvider } from "../../contexts/CartContext";
import { isTransactional } from "../../hooks/siteData";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {WebStoreContextProvider} from "../../contexts/WebStoreContext";
import WebStore from "../WebStore";

export default function Layout({ children }) {
    const siteTransactionalType = isTransactional() ? 'transactional' : 'non-transactional';

    return (
        <CustomerContextProvider>
            <WebStoreContextProvider>
                <CartContextProvider>
                    <Helmet htmlAttributes={{class: siteTransactionalType}}>
                        <script src="//cdn.jsdelivr.net/npm/@nanoporetech-digital/components@latest/dist/nano-components/nano-components.esm.js" type="module" />
                    </Helmet>
                    <ToastContainer />

                    <WebStore />
                    <GlobalNav>
                        {children}
                    </GlobalNav>

                </CartContextProvider>
            </WebStoreContextProvider>
            <style jsx global>{`

            .contentWrapper{
                max-width:1440px;
            }

            @media screen and (max-width: 721px) {
                    .contentWrapper{
                    margin: 0 14px;
                }
             }

            @media screen and (min-width: 721px) {
                .contentWrapper {
                    margin: 0 50px;
                }
            }

        @media screen and (min-width: 1125px) {
            .contentWrapper {
                margin: 0 83px;
            }
        }
            @media screen and (min-width: 1647px) {
                .contentWrapper {
                    margin: 0 auto;
                }
            }

            .productName {
                color: #455556;
                font-weight: bold;
                font-size: 1.75rem;
                line-height: 2.6rem;
                margin-bottom: 0;
            }

            .headerInner {
                display: flex;
                flex-direction:row;
            }
            .productTitle {
                display: flex;
                flex-direction: column;
                margin-top: 2.3rem;
                line-height: 1.625rem;
                gap: 0.2rem;
                position: relative;
                z-index: 2;
            }

            @media only screen and (max-width: 768px) {
             .backButton.mobile-hidden {
                clip: rect(0 0 0 0);
                position: absolute;
               }

               .productTitle {
                margin-top: 1.3rem!important;
            }

            .productName{
                line-height:2rem!important;
                max-width: 247px !important;
            }
           }

      `}</style>
        </CustomerContextProvider>
    );
}
