import {gql, useQuery} from "@apollo/client";
import {isTransactional, isMainSite} from "../../hooks/siteData";
import {getStoreFromCountryIso, redirectIfRequired, isDisableGeoip} from "../../utils/webStore";
import React from "react";
import {useWebStoreContext} from "../../contexts/WebStoreContext";
import CountryDropdown from "./CountryDropdown";
import {useCustomerContext} from "../../contexts/CustomerContext";

export default function WebStore() {
    if (typeof window === "undefined") {
        return null;
    }

    if (isDisableGeoip() || !isTransactional() || !isMainSite() ) {
        return null;
    }

    const {webstore, setWebstore} = useWebStoreContext();
    const {customer} = useCustomerContext();

    const geoQuery = gql`
        query {
            geoIP {
                    country {
                    isoCode
                    name
                }
            }
        }
    `;

    const {data} = useQuery(geoQuery, {
        skip: webstore.geoIpCountryIso,

        onCompleted: data => {
            setWebstore({
                ...webstore,
                geoIpCountryIso: data.geoIP.country.isoCode,
                geoIpCountryName: data.geoIP.country.name,
                guessedStore: getStoreFromCountryIso(data.geoIP.country.isoCode)
            })
        }
    })

    if (! webstore.designatedStore) {
        if (customer.intended_store_view) {
            setWebstore({
                ...webstore,
                designatedStore: customer.intended_store_view
            })
        }
        else if (webstore.countryIsoPreference) {
            setWebstore({
                ...webstore,
                designatedStore: getStoreFromCountryIso(webstore.countryIsoPreference)
            })
        }
        else if (webstore.guessedStore) {
            redirectIfRequired(webstore.guessedStore)
        }
    }

    if (webstore.designatedStore) {
        redirectIfRequired(webstore.designatedStore)
    }

    return <CountryDropdown />;
}

const setDesignatedStoreByCustomerCurrency = (webstore, setWebstore) => {
    return null;
}

const setDesignatedStoreByGeoIP = (webstore, setWebstore, makeGeoQuery, geoQueryResult) => {
    if (!geoQueryResult.called) {
        console.log("calling");
        makeGeoQuery();
    }

    if (geoQueryResult.called && geoQueryResult.data) {
        console.log("geo", geoQueryResult.data.GeoIP.country.isoCode)
        const countryIso = geoQueryResult.data.GeoIP.country.isoCode

        // setWebstore({
        //     ...webstore,
        //     geoIpCountryIso: countryIso,
        //     designatedStore: getStoreFromCountryIso(countryIso)
        // })
    }
}
