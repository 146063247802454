import {ApolloClient, HttpLink, InMemoryCache} from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import fetch from 'cross-fetch';
import { getStoredCustomer } from "../contexts/CustomerContext";
import { getSiteKey } from "../hooks/siteData";

const httpLink = createHttpLink({
    uri: process.env.GATSBY_MAGENTO_BACKEND_URL + 'graphql',
    credentials: 'include',
    fetch
});

const authLink = setContext((_, { headers }) => {
    // get the customer from local storage
    const customer = getStoredCustomer();
    const intendedStoreView = customer.intended_store_view ?? '';

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: customer.token ? `Bearer ${customer.token}` : "",
            store: (intendedStoreView.length ? intendedStoreView : getSiteKey()),
        }
    }
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        possibleTypes: {
            CategoryInterface: [
                'CategoryTree'
            ],
            ProductInterface: [
                'SimpleProduct',
                'BundleProduct',
                'ConfigurableProduct',
                'GroupedProduct',
                'VirtualProduct',
            ],
        }
    })
});

const TOKEN = 'iqH3_aDrFeYToqKd4I5csn-PqBcZnM38G24XhqEhAOU';
const SPACE = 'obrbum38fjyj';
const ENVIRONMENT = 'dev';


// export const client = new ApolloClient({
//     uri: `https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/${ENVIRONMENT}?access_token=${TOKEN}`,
//     fetch
// })
