import React, { useState } from 'react';
import { NanoMenuDrawer, NanoNavItem, NanoIcon } from '@nanoporetech-digital/components-react';
import CUSTOMER_PERSONAS from './customerPersonas.graphql';
import { useLazyQuery } from '@apollo/client';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import Persona from './persona';
import styles from './style.module.css';

export default function PersonaSwitcher() {
    const { customer, resetCustomer } = useCustomerContext();
    const [getPersonas, {called, data}] = useLazyQuery(CUSTOMER_PERSONAS, {fetchPolicy: 'network-only'});
    const closeDrawer = e => {
        e.target.closest('nano-nav-item').open = false;
    }

    if (!called && customer.token) {
        getPersonas();
    }

    const personas = data?.customerPersonas?.items ?? [];
    if (personas.length) {
        return(
            <NanoMenuDrawer hide-height="false" open="false">
                <NanoNavItem>
                    <NanoIcon slot="icon-start" name="light/user" />
                    <NanoIcon slot="icon-end" name="light/chevron-right" />
                    <div slot="secondary">
                        <div className={styles.close}>
                            <button onClick={closeDrawer}>Close</button>
                        </div>
                        {personas.length > 0 && <PersonaList personas={personas} />}
                    </div>
                </NanoNavItem>
            </NanoMenuDrawer>
        );
    } else {
        if (data && customer?.token?.length) {
            console.log('Forcing customer reset');
            resetCustomer();
        }
    }

    return null;
}

export function PersonaList({personas}) {
    const [activePersona] = personas.filter(({is_active}) => is_active === true);

    const [activePersonaId, setActivePersonaId] = useState(activePersona.id);

    return (
        <ul className={styles.personas}>
            {personas.map(persona =>
                <Persona
                    key={persona.id}
                    persona={persona}
                    activePersonaId={activePersonaId}
                    setActivePersonaId={setActivePersonaId}
                />
            )}
        </ul>
    );
}
