export default function useEnvironment() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);

    if (
        url.hostname.startsWith('staging')
    ) return 'staging';

    if (
        url.hostname.startsWith('test')
    ) return 'test';

    if (
        url.hostname.startsWith('dev') ||
        url.hostname.startsWith('localhost')
    ) return 'dev';

    return 'prod';
}

export function isProduction() {
    return useEnvironment()==='prod';
}
