import React, {createContext, useContext} from 'react';
import useLocalStorage from "../utils/useLocalStorage";

const emptyCustomer = {
    token: '',
    name: '',
    has_extra_permission: false,
    intended_store_view: '',
};

const CustomerContext = createContext({});

export function CustomerContextProvider(props) {

    const [customer, setCustomer] = useLocalStorage('ont_customer', emptyCustomer);

    const resetCustomer = () => setCustomer(emptyCustomer);

    return (
        <CustomerContext.Provider value={{customer, setCustomer, resetCustomer}}>
            {props.children}
        </CustomerContext.Provider>
    );
}

export const useCustomerContext = () => useContext(CustomerContext);

// hooks can only be used inside a component, so this allows direct access
export const getStoredCustomer = () => {
    if (typeof window === "undefined") {return {};}

    const customerJson = localStorage.getItem('ont_customer');
    return customerJson ? JSON.parse(customerJson) : {};
}
