import React, {createContext, useContext} from 'react';
import useLocalStorage from "../utils/useLocalStorage";

const emptyCart = {
    id: '',
    total_quantity: 0,
    items: [],
    subtotal: {}
};

const CartContext = createContext(emptyCart);

export function CartContextProvider(props) {

    const [cart, setCart] = useLocalStorage('ont_cart', emptyCart);

    const resetCart = () => setCart(emptyCart);

    const updateCart = newCart => {
        if (cart.id===newCart.id 
            && cart.total_quantity===newCart.total_quantity 
            && cart.subtotal===newCart.prices.subtotal_excluding_tax 
            && cart.items.length===newCart.items.length) {
                console.log('Skipping cart update due to no material change');
                return;
        }
        setCart({
            id: newCart.id, 
            total_quantity: newCart.total_quantity, 
            items: newCart.items, 
            subtotal: newCart.prices.subtotal_excluding_tax
        });
    }

    return (
        <CartContext.Provider value={{cart, setCart, resetCart, updateCart}}>
            {props.children}
        </CartContext.Provider>
    );
}

export const useCartContext = () => useContext(CartContext);
