import React from 'react';
import { navigate } from 'gatsby';
import { useCustomerContext } from '../../contexts/CustomerContext';
import { NanoGlobalNav, NanoGlobalSearchResults } from '@nanoporetech-digital/components-react';
import Footer from "../Footer";
import styles from "./style.module.css";
import cx from 'classnames';
import PersonaSwitcher from "./PersonaSwitcher/personaSwitcher";
import { useCartContext } from '../../contexts/CartContext';
import useLoginLink, { getReAuthenticateUrl, getCartUrl } from '../../hooks/useLoginLink';
import { isProduction } from '../../hooks/useEnvironment';
import useSiteData, { isDistributorStore } from '../../hooks/siteData';

export default function GlobalNav({children}) {
    const { customer } = useCustomerContext();
    const {cart} = useCartContext();
    const cartCount = cart.total_quantity;
    const { home } = useSiteData();
    const reAuthLink = getReAuthenticateUrl();
    const loginLink = useLoginLink();
    const cartLink = getCartUrl();
    const env = isProduction()
        ? {env: "prod", search: "store_prod"}
        : {env: "test", search: "store_test"};

    function globalNavReady(e) {
        if (typeof window !== 'undefined') {
            if (e.target.myAccountUser?.id?.length || isDistributorStore()) {
                if (!customer.token) {
                    const url = new URL(window.location.href);
                    if (!url.pathname.includes('authenticate')) {
                        console.log('Mandatory login');
                        navigate(loginLink);
                    }
                }
            }
        }
    }

    return(
        <NanoGlobalNav
        id="global-nav-main"
        logo-url={home}
        env={env.env}
        search-index={env.search}
        show-search="false"
        cart-url={cartLink} 
        cartCount={cartCount}
        sso-redirect={reAuthLink}
        onNanoIsReady={e => globalNavReady(e)}>
            <PersonaSwitcher />
            <NanoGlobalSearchResults>
                <main className={cx("site-main", styles.siteMain)}>
                    <div className={cx("site-main__content", styles.siteMain__content)}>{children}</div>
                </main>
                <Footer />
            </NanoGlobalSearchResults>
        </NanoGlobalNav>
    );
}
