import useLocalStorage from '../utils/useLocalStorage';
import { isProduction } from '../hooks/useEnvironment';
import { getUrlPathForCurrentStore } from '../hooks/siteData';

export default function useLoginLink() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);
    const isAuthUrl = url.pathname.includes('authenticate');
    const newDest = url.pathname + url.search;
    const [authDestUrl, setAuthDestUrl] = useLocalStorage('ont_auth_dest_path');

    if (isAuthUrl) {
        if (!authDestUrl) setAuthDestUrl('/');
    } else {
        if (authDestUrl !== newDest) setAuthDestUrl(newDest);
    }

    const link = isProduction()
        ? "https://id.nanoporetech.com/as/authorization.oauth2?client_id=magento&response_type=code&pfidpadapterid=ad..HTML&redirect_uri="
        : "https://id-test.nanoporetech.com/as/authorization.oauth2?client_id=magento_test&response_type=code&pfidpadapterid=ad..HTML&redirect_uri=";

    return link + getAuthenticateUrl();
}

export function getAuthenticateUrl() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);
    return `${url.origin}/authenticate/`;
}

export function getReAuthenticateUrl() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);
    return `${url.origin}/reauthenticate/`;
}

export function getCartUrl() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);
    const path = getUrlPathForCurrentStore();
    return `${url.origin}${path}cart/`;
}
