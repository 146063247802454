import React, {useState} from "react";
import {navigate} from "gatsby";
import UiSelect from "../../Ui/UiSelect";
import {useWebStoreContext} from "../../../contexts/WebStoreContext";
import UiButton from "../../Ui/UiButton";
import {getLabel} from "../../../hooks/siteData";
import {isExemptPage} from '../../../utils/webStore';
import styles from './style.module.css';
import { NanoGrid, NanoGridItem, NanoSelect} from '@nanoporetech-digital/components-react';

export default function CountryDropdown() {
    const {webstore, setWebstore} = useWebStoreContext();
    const [selection, setSelection] = useState(webstore.geoIpCountryIso);

    if (!webstore.geoIpCountryIso || webstore.countryIsoPreference || webstore.designatedStore || isExemptPage()) {
        return null;
    }

    if (!selection && webstore.geoIpCountryIso) {   // because webstore.geoIpCountryIso is async
        setSelection(webstore.geoIpCountryIso);
    }

    const importFlags = r => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const images = importFlags(require.context('../../../assets/flags/', false, /\.(png|jpe?g|svg)$/));
    const imgSrc = images[webstore.geoIpCountryIso.toLowerCase() + '.svg'];

    const updateSelection = ({value}) => {
        setSelection(value);
        if (value==='_other') navigate("/countrySelect");
    }

    const countryOptions = [
        {value: webstore.geoIpCountryIso, label: <div><img src={imgSrc} className={styles.flag}/>{webstore.geoIpCountryName}</div>},
        {value: "_other", label: "Other country or region"}
    ];

    const optionStyle = {
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            backgroundColor: (state.isSelected ? '#0084a9' : 'white'),
            color: (state.isSelected ? 'white' : '#111'),
            textAlign: 'left'
        })
    }

    const chooseStore = code => {
        if (code) {
            setWebstore({
                ...webstore,
                countryIsoPreference: code
            })
        }
        else if (selection === "_other") {
            navigate("/countrySelect");
        }
        else {
            setWebstore({
                ...webstore,
                countryIsoPreference: selection
            })
        }
    };

    return (
        <div className={styles.modal}>
        <div  className={styles.switcher}>
        <NanoGrid l-cols="2" s-cols="3" xl-cols="10" className="contentWrapper">
                <NanoGridItem  grid-states="xl-col-span-5 xl-col-start-1 xl-row-start-1 s-col-span-3" className={styles.info}>
                    <span>You have arrived at our {getLabel()} store.  Select your region to see content specific to your location.</span>
                </NanoGridItem>
                <NanoGridItem  grid-states="xl-col-span-4 xl-col-start-6 xl-row-start-1 s-row-start-2 s-col-span-3" className={styles.dropdown}>
                    <UiSelect
                        isSearchable={false}
                        options={countryOptions}
                        onChange={updateSelection}
                        defaultValue={countryOptions[0]}
                        styles={optionStyle}
                    />
                    <div>
                        <UiButton className="button button--primary" onClick={() => chooseStore()}>
                            Continue
                        </UiButton>
                    </div>
                </NanoGridItem>
                <NanoGridItem  grid-states="xl-col-span-1 xl-col-start-9 xl-row-start-1" className={styles.dismiss}>
                    <a onClick={() => chooseStore(webstore.geoIpCountryIso)}>x</a>
                </NanoGridItem>

            </NanoGrid>
            </div>
        </div>
    )
}
