import React from 'react';
import Select, { components } from 'react-select';
import triangle from './triangle.svg';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <img src={triangle} alt="v" />
            </components.DropdownIndicator>
        )
    );
};

const customStyles = {
    indicatorSeparator: (defaultStyles) => ({...defaultStyles, marginTop: 0, marginBottom: 0, backgroundColor: '#99a3a6'}),
    dropdownIndicator: (defaultStyles) => ({...defaultStyles, padding: '7px 11px 6px', color: '#99a3a6'}),
    control: (defaultStyles) => ({...defaultStyles, borderColor:'#99a3a6', borderRadius: 3})/*,
    option: (defaultStyles, state) => ({...defaultStyles, backgroundColor: (state.isSelected ? '#e4f1f5' : 'white')})*/
}

const UiSelect = props => {
    return(
        <Select
            isSearchable={props.isSearchable}
            placeholder={props.placeholder ? props.placeholder : 'Select ...'}
            onChange={props.onChange}
            options={props.options}
            formatOptionLabel={props.formatOptionLabel}
            value={props.value}
            components={{DropdownIndicator}}
            styles={{...customStyles, ...props.styles}}
            id={props.id}
            defaultValue={props.defaultValue}
        />
    );
};

export default UiSelect;
