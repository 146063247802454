import React from 'react';
import { link_collections } from './hardcodedLinks';
import {FontAwesomeIcon, FontAwesomeIcons} from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import iso27001 from '../../ont-styleguide/assets/imgs/iso-27001.svg';
import iso9001 from '../../ont-styleguide/assets/imgs/iso-9001.svg';
import ontLogo from '../../ont-styleguide/assets/imgs/ont-logo.svg';
import { isChinaStore, shouldShowPrice } from '../../hooks/siteData';
import { shouldShowQrCodes } from "../../hooks/siteData";
import nanoQr from '../QrCode/Nanoporetech.png';

const LinkCollection = ({ header, links, isFirst }) => {
    return (
        <div className="footer__column">
            <h4 className="footer__title">{header}</h4>
            <nav>
                <ul>
                    {
                        links.map((link) =>
                            <li key={link.text} className="footer__item"><a href={ link.href }>{ link.text }</a></li>
                        )
                    }

                    {isFirst && shouldShowQrCodes() &&
                        <li className="footer__item">
                            <img src={nanoQr} width="100" />
                            <br/>
                            WeChat: Nanoporetech
                        </li>}
                </ul>
            </nav>
        </div>
    )
}

const Footer = ({}) => {
    return (
        <footer className="footer">
            <div className="footer__languages">
                <div className="footer__inner">
                    <a href="http://nanoporetech.jp/cn" className="footer__social-flag cn">中文</a>
                    <a href="http://nanoporetech.jp" className="footer__social-flag jp">日本語</a>
                    <a href="http://nanoporetech.com" className="footer__social-flag gb">English</a>
                </div>
            </div>
            <div className="footer__links">
                <div className="footer__inner">
                    {
                        link_collections.map((link_collection, i) =>
                            <LinkCollection
                                key={ link_collection.header }
                                header={ link_collection.header }
                                links={ link_collection.links }
                                isFirst={i==0}
                            />
                        )
                    }
                </div>
                <div className="footer__inner">
                    <div className="footer__extras">
                        <div className="footer__logo">
                            <img src={ontLogo} alt="Oxford Nanopore Technologies" />
                        </div>
                        <div className="footer__social">
                            <div className="footer__social-icons">
                                <a href="https://twitter.com/nanopore" target="_blank"
                                   className="footer__social-icon"><FontAwesomeIcon icon={faTwitter} /></a>
                                <a href="https://www.youtube.com/channel/UC5yMlYjHSgFfZ37LYq-dzig" target="_blank"
                                   className="footer__social-icon"><FontAwesomeIcon icon={faYoutube} /></a>
                                <a href="https://www.facebook.com/Oxford-Nanopore-Technologies-251034380246/"
                                   target="_blank" className="footer__social-icon"><FontAwesomeIcon icon={faFacebookF} /></a>
                            </div>
                        </div>
                        <a className="footer__quality" href="http://nanoporetech.com/commercial_information#tabs-0=policies">
                            <div className="footer__quality-item">
                                <img src={iso27001} alt="ISO 27001" />
                            </div>
                            <div className="footer__quality-item">
                                <img src={iso9001} alt="ISO 9001" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer__bottom">
                <div className="footer__inner">
                    <div id="block-footer" className="footer__nav">
                        <ul className="nav__footer">
                            <li className="nav__menu-item--footer nav__menu-item intellectual_property">
                                <a href="http://nanoporetech.com/about-us/intellectual-property" className="intellectual_property">Intellectual property</a>
                            </li>
                            <li className="nav__menu-item--footer nav__menu-item corporate_reporting">
                                <a href="http://nanoporetech.com/about-us/corporate-reporting" className="corporate_reporting">Corporate reporting</a>
                            </li>
                            <li className="nav__menu-item--footer nav__menu-item privacy_&amp;_cookie_policies">
                                <a href="http://nanoporetech.com/commercial_information#tabs-0=policies"
                                   className="privacy_&amp;_cookie_policies">Privacy &amp; cookie policies</a>
                            </li>
                            <li className="nav__menu-item--footer nav__menu-item terms_&amp;_conditions">
                                <a href="http://nanoporetech.com/commercial_information" className="terms_&amp;_conditions">Terms &amp; conditions</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer__legal">
                <div className="footer__inner">
                    <div id="block-globalfooterlegal" className="block block-block-content">
                        <div>&copy; 2008 - 2021 Oxford Nanopore Technologies. All rights reserved. Registered Office: Gosling
                        Building, Edmund Halley Road, Oxford Science Park, OX4 4DQ, UK | Registered No. 05386273 | VAT
                        No 336942382. Oxford Nanopore Technologies, the Wheel icon, EPI2ME, Flongle, GridION, Metrichor,
                        MinION, MinIT, MinKNOW, Plongle, PromethION, SmidgION, Ubik and VolTRAX are registered
                        trademarks of Oxford Nanopore Technologies Limited in various countries. Flongle, GridION,
                        MinION, PromethION and VolTRAX are currently for research use only. Oxford Nanopore products are
                        currently for research use only.</div>
                        {isChinaStore() && <p>Shanghai ICP number 沪ICP备20017565号</p>}
                    </div>
                </div>
            </div>
            <style jsx>{`
                .footer__quality-item {
                    max-width: 7rem;
                    -webkit-box-flex: 1;
                    flex: 1;
                    padding-right: 1rem;
                }
                .footer__nav ul {
                    text-align: center;
                    font-size: 1rem;
                }
                .footer__nav ul li a {
                    color: #fff;
                    font-size: 0.75rem;

                }
                .footer__legal p {
                    font-size: 10px;
                    line-height: 16px;
                }
            `}</style>
        </footer>
    );
}

export default Footer;
